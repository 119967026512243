html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #282c34; /* Same gray background */
}

.Add-ons{
  /* background-color: #668ace74; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.full-board{
  display: flex;
  justify-content: center; /* Center horizontally*/
  align-items: flex-start;
  width: 100vw; /* 100% of the viewport width */
  height: 100vh; /* 100% of the viewport height */
  position: fixed; /* Keeps the div in place even when scrolling */
  top: 0;
  left: 0;
  margin-top: 295px;;
}

.player-scores{
  display: flex;
  /* margin-top: -177px; */
  margin-right: 3px;
  color: #282c34;
}

.target-score{
  margin-top: 4px;
  background-color: #424853; 
  text-align: center;
  border: none;
  font-size: 18px;
  padding: 4px;
  color:  #131517;
  /* padding-left: -15px;
  padding-right: -15px; */
}

.target-score-label{
  color: gray;
  font-size: 24px;
  font-weight: bold;
  align-items: flex-start;
}

.add-score-buttons{
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.grid-container {
  display: flex;
  /* flex-direction: column; */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* height: 100vh; Full height of the viewport */
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr)); /* Adjust columns to fit the container */
  grid-gap: 2px; /* Spacing between buttons */
  padding: 1px;
}

.grid-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; /* Set height equal to the minimum width of the grid column */
  border: 2px solid #000000;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.grid-button:hover {
  background-color: #45a049;
}

.bust-button{
  margin-left: 2px;
  background-color: #f44336;
  border: 2px solid #000000;
  font-size: 16px;
  width: 76px;
  height: 38px; /* Set height equal to the minimum width of the grid column */
}

.bust-button:hover {
  background-color: #d73c31;
}

.Clear-button{
  background-color: #cbcbcb;
  border: 2px solid #000000;
  font-size: 16px;
  width: 76px;
  height: 38px; /* Set height equal to the minimum width of the grid column */
  margin-right: 2px;
}

.Clear-button:hover{
  background-color: #b1b1b1;
}

.textbox-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; Center horizontally */
  align-items: center; /* Center vertically */
}



.textbox {
  border: 3px groove #8f8f8f;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  width: 116px; /* Or any desired width */
  color:#ddd;
}

.textbox:focus {
  outline: none;
}

.detail-box {
  border: 3px solid #ddd;
  padding: 10px;
  color:#ddd;
  width: 100px; /* Or any desired width */
  text-align: center;
  font-size: 21px;
}

.temp-score-left {
  /* border-right: 3px solid #ddd; */
  padding: 10px;
  color:#ddd;
  width: 100px; /* Or any desired width */
  text-align: center;
  font-size: 16px;
  width: 102px; /* Or any desired width */
}

.temp-score-right {
  /* border-left: 3px solid #ddd; */
  padding: 10px;
  color:#ddd;
  width: 100px; /* Or any desired width */
  text-align: center;
  font-size: 16px;
}

.enter-button {
  border: 2px solid #000000;
  width: 100px; /* Or any desired width */
  height: 38px;
  text-align: center;
  font-size: 16px;
  background-color: #FFD700;
}

.enter-button:hover {
  background-color: #dabc0d;
}

.freeze-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.26); /* semi-transparent white */
  z-index: 9999; /* High z-index to cover everything */
  font-weight: Bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px; /* Large font size for the win message */
  color: #ffffff; /* Font color */
  font-weight: bold;
  text-transform: uppercase; /* Optional: Makes the text uppercase */
  text-shadow: 
    -3px -3px 0 #000,  
     3px -3px 0 #000,
    -2px  1px 0 #000,
     2px  1px 0 #000; /* Shadow in black */
}

.plus{
  /* padding-left: 10px; */
  /* margin-top: -1px;
  margin-left: -6px; margin-right: -6px;
  margin-bottom: -5px;
  border-radius: 3.3px; */
  /* font-weight: bold; */
  font-size: 18px;
  border: 4px #000;
}